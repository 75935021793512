import { IconSizeEnum } from "lib/Icon"
import React from "react"
import styled from "styled-components"

const StyledIcon = styled.svg<any>`
  fill: currentColor;
  display: inline-block;

  ${({ size }) => {
    switch (size) {
      case IconSizeEnum.XXL:
        return `
          height: ${2 * 2}rem;
          width: ${2 * 2}rem;
        `
      case IconSizeEnum.XL:
        return `
          height: ${2 * 1.6}rem;
          width: ${2 * 1.6}rem;
        `
      case IconSizeEnum.LG:
        return `
          height: ${2 * 1.25}rem;
          width: ${2 * 1.25}rem;
        `
      case IconSizeEnum.SM:
        return `
          height: 1rem;
          width: 1rem;
        `
      case IconSizeEnum.XS:
        return `
          height: ${2 * 0.25}rem;
          width: ${2 * 0.25}rem;
        `
      case IconSizeEnum.AUTO:
        return `
          height: auto;
          width: auto;
        `
      case IconSizeEnum.FILL:
        return `
          height: 100%;
          width: 100%;
        `
      default:
        return `
          height: 2rem;
          width: 2rem;
        `
    }
  }}
`

type IconType = {
  size?: IconSizeEnum
  icon: string
}

function Icon({ icon, size = IconSizeEnum.MD, ...props }: IconType) {
  return (
    <StyledIcon size={size} {...props}>
      <use xlinkHref={`/icons.svg#icon-${icon}`} />
    </StyledIcon>
  )
}

export { Icon }
