import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
// import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import { metaResolver } from "utils/seoResolver"
import Facebook from "./Facebook"
import Twitter from "./Twitter"

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEOComponent = React.memo(
  ({
    title,
    description,
    author,
    slug,
    lang,
    images,
    siteUrl,
    metaTitlePrefix,
    metaTitleAppendix,
    logo,
    facebook,
    twitter,
    hideFromGoogle,
    ...props
  }) => {
    const {
      data: {
        site: { defaultMeta },
      },
    } = props

    const seo = metaResolver(
      {
        title,
        description,
        author,
        slug,
        lang,
        images,
        siteUrl,
        metaTitlePrefix,
        metaTitleAppendix,
        logo,
        facebook,
        twitter,
      },
      defaultMeta
    )

    return (
      <>
        <Helmet title={seo.title}>
          <html lang={seo.siteLang} />
          <meta name="description" content={seo.description} />
          <meta name="image" content={`${seo.mainImage?.image.url}`} />
          <script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-cbid="859afa55-df6a-4946-847e-ef081acc94e0"
            data-blockingmode="manual"
            type="text/javascript"
          />
          <link rel="canonical" href={`${seo.siteUrl}${seo.slug}`} />
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=UA-171398598-1`}
          />
          <script
            defer
            data-domain="clevertrack.dk"
            src={`https://plausible.io/js/script.js`}
          />
          <script>
            {`window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-171398598-1');
            `}
          </script>
          <script nonce="OeNrp1S3PckJ/Rx3">
            {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
            n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-P397QJD');
          `}
          </script>
          <script data-cookieconsent="statistics">
            {`
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2804378,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}
          </script>
          <script
            data-cookieconsent="marketing"
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js.hs-scripts.com/7430847.js"
          />
          {(hideFromGoogle ||
            process.env.GATSBY_ACTIVE_ENV !== "production") && (
              <meta name="robots" content="noindex,nofollow" />
            )}
        </Helmet>
        {seo.images.length ? (
          seo.images.map(({ image, imageSharp }, idx) => {
            return (
              <Facebook
                key={`fb_${idx}`}
                desc={seo.description}
                title={seo.title}
                type="website"
                url={`${seo.siteUrl}${seo.slug}`}
                locale={seo.ogLang}
                name={facebook}
              >
                <meta
                  key={`meta_img_${idx}`}
                  property="og:image"
                  content={`${seo.mainImage?.image.url}`}
                />
                <meta
                  key={`meta_img_alt_${idx}`}
                  property="og:image:alt"
                  content={image?.alt}
                />
              </Facebook>
            )
          })
        ) : (
          <Facebook
            desc={seo.description}
            title={seo.title}
            type="website"
            url={`${seo.siteUrl}${seo.slug}`}
            locale={seo.ogLang}
            name={facebook}
          >
            <meta property="og:image" content={`${seo.mainImage?.image.url}`} />
            <meta property="og:image:alt" content={`${seo.images?.alt}`} />
          </Facebook>
        )}
        <Twitter
          title={seo.title}
          siteUrl={seo.siteUrl}
          image={seo.mainImage}
          desc={seo.description}
          username={twitter}
        />
      </>
    )
  }
)

const seoQuery = graphql`
  {
    site {
      defaultMeta: siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultAuthor: author
        defaultLang: lang
        defaultSiteUrl: siteUrl
        defaultMetaTitlePrefix: metaTitlePrefix
        defaultMetaTitleAppendix: metaTitleAppendix
        defaultLogo: logo
        defaultFacebook: facebook
        defaultTwitter: twitter
      }
    }
  }
`

const SEO = (props) => (
  <StaticQuery
    query={seoQuery}
    render={(data) => <SEOComponent data={data} {...props} />}
  />
)

export default SEO

SEOComponent.defaultProps = {
  title: null,
  description: null,
  author: null,
  slug: null,
  lang: null,
  siteUrl: null,
  metaTitlePrefix: null,
  metaTitleAppendix: null,
  logo: null,
  facebook: null,
  twitter: null,
  images: [],
}

SEOComponent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.string,
  slug: PropTypes.string,
  lang: PropTypes.string,
  siteUrl: PropTypes.string,
  metaTitlePrefix: PropTypes.string,
  metaTitleAppendix: PropTypes.string,
  logo: PropTypes.string,
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object),
}
