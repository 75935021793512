import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from "react"
import styled from "styled-components"
import isEqual from "lodash-es/isEqual"
import DefaultValidationInput from "lib/ValidationInput"
import {
  getDefaultValidationMessagesByLang,
  ValidationMessages,
} from "utils/validation"
import { FormContext } from "../Form/context"
import { FormTypes, FormActions } from "../Form/actions"

const StyledValidationInput = styled(({ renderAs, ...props }) => (
  <DefaultValidationInput as={renderAs} {...props} />
))`
  box-shadow: none;
  transition: all ease-out 0.15s;
  border: none;
`

interface ValidationInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string
  customValidationMessages?: ValidationMessages
}

const ValidationInput: React.FC<ValidationInputProps> = ({
  id,
  customValidationMessages,
  ...props
}) => {
  const [error, setError] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const inputRef = useRef(id)
  const lang = "da"
  const {
    state: { fields },
    dispatch,
  } = useContext(FormContext)

  const validationMessages = customValidationMessages
    ? {
        ...getDefaultValidationMessagesByLang(lang),
        ...customValidationMessages,
      }
    : getDefaultValidationMessagesByLang(lang)

  const onValidateInputHandler = (errorMsg, isValid) => {
    const fieldWasPreviouslyValidated = fields.find(
      (field) => field.id === inputRef.current
    )
    if (!fieldWasPreviouslyValidated && errorMsg !== null) {
      // Field doesn't exists, and there's an error message
      dispatch(
        FormActions(FormTypes.AddFieldError, {
          fieldId: inputRef.current,
          error: { error: !isValid, errorMessage: errorMsg },
        })
      )
      return
    } else if (
      // Field exist in array, but no errors are on it. Call dispatch once to remove it.
      fieldWasPreviouslyValidated &&
      errorMsg === null &&
      error === false
    ) {
      dispatch(
        FormActions(FormTypes.RemoveFieldError, {
          fieldId: inputRef.current,
        })
      )
      return
    } else if (
      // Field exists, error is new
      fieldWasPreviouslyValidated &&
      !isEqual(fieldWasPreviouslyValidated, {
        id: inputRef.current,
        error: !isValid,
        errorMessage: errorMsg,
      })
    ) {
      dispatch(
        FormActions(FormTypes.UpdateFieldError, {
          fieldId: inputRef.current,
          error: { error: !isValid, errorMessage: errorMsg },
        })
      )
      return
    }
  }

  useEffect(() => {
    if (inputRef.current === id) {
      const validatedField = fields.find(
        (field) => field.id === inputRef.current
      )
      // Only call this on the input that are actually changed.
      if (!validatedField) {
        setError(false)
        setErrorMessage(null)
      } else if (validatedField.error.error) {
        setError(true)
        setErrorMessage(validatedField.error.errorMessage)
      }
    }
  }, [fields])

  return (
    <StyledValidationInput
      validationMessages={validationMessages}
      onValidate={onValidateInputHandler}
      {...props}
    />
  )
}

export { ValidationInput }
