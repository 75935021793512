import React from "react"
import "twin.macro"
import styled from "styled-components"
import { siteTheme as theme } from "theme"

const StyledButtonGroup = styled.div<IButtonGroup>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: space-between;
  ${(props) => (props.multiple ? `column-gap: 2rem;` : ``)}

  ${(props) =>
    props.sticky &&
    `
    position: fixed;
    ${
      props.sticky === "bottom"
        ? "position: sticky; bottom: 0; left: 0; right: 0;"
        : "position: sticky; bottom: 6.7rem;"
    }
    border-top: 1px solid ${theme.colors.grayLight};
    z-index: 60;
  `};

  > * {
    flex: 1 1 35%;
    min-width: min-content;
    text-align: center;

    &:only-child {
      flex: 1 1 100%;
    }
  }

  ${(props) => {
    switch (props.position) {
      case "right":
        return `
            justify-content: flex-end;
            padding-right: 0;
            > * {
              margin-left: 2rem;
            }
          `
      case "center":
        return `
            justify-content: center;
          `
      case "between":
        return `
            justify-content: space-between;
          `
      case "around":
        return `
            justify-content: space-around;
          `
      case "left":
        return `
            justify-content: flex-start;
            padding-left: 0;
            > * {
              margin-right: 2rem;
            }
          `
      default:
        return `
            justify-content: center;
          `
    }
  }}

  ${theme.media.tablet_landscape_up`
    padding-top: 1rem;
    > * {
      flex: 1 1 auto;
      width: auto;

      &:only-child {
        flex: 0 1 100%;
      }
    }
  `}
`

type ButtonGroupDirection = "column" | "row" | "column-reverse" | "row-reverse"

type ButtonGroupPosition = "left" | "center" | "right" | "between" | "around"

interface IButtonGroup {
  sticky?: boolean | "bottom"
  children: React.ReactNode
  position?: ButtonGroupPosition
  direction?: ButtonGroupDirection
  multiple?: Boolean
}

const ButtonGroup: React.FC<IButtonGroup> = ({
  sticky = false,
  direction = "row",
  children,
  ...props
}) => {
  return (
    <StyledButtonGroup
      sticky={sticky}
      direction={direction}
      multiple={children.hasOwnProperty("length")}
      {...props}
    >
      {children}
    </StyledButtonGroup>
  )
}

export { ButtonGroup }
