import React from "react"
import { Icon } from "../Icon"
import "twin.macro"
import styled from "styled-components"
import { Tag } from "../Tag"

const StyledWrapper = styled.div`
  position: relative;
  top: -1rem;
`

const StyledTags = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
`

const StyledTag = styled(Tag)`
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 0.2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  user-select: none;
`

const StyledRemoveableTag = styled(Tag)`
  // padding-right: 0.2rem;

  svg {
    width: 1.2rem;
  }
`

const StyledIcon = styled(Icon)`
  max-height: 1.2rem;
`

function TagInput({ tagMap, tags, onAddTag, onRemoveTag, ...props }) {
  return (
    <StyledWrapper {...props}>
      <StyledTags>
        {tagMap.map((tag, i) => {
          const toggled =
            tags && tags.length > 0 ? tags.some((f) => f === tag) : "toggleLess"
          return typeof tag !== "object" ? (
            <StyledTag
              show
              title={tag}
              key={`tag_option_${i}`}
              onClick={() =>
                toggled !== "toggleLess"
                  ? toggled
                    ? onRemoveTag(tag)
                    : onAddTag(tag)
                  : {}
              }
              style={{
                background:
                  toggled || toggled === "toggleLess" ? "#1EA644" : "#5f6b6d",
                cursor: toggled !== "toggleLess" ? "pointer" : "default",
              }}
            >
              {toggled !== "toggleLess" ? (
                <StyledIcon icon={toggled ? "check" : "plus"} />
              ) : (
                <StyledIcon
                  tw="cursor-pointer"
                  icon="close"
                  onClick={() => onRemoveTag(tag)}
                />
              )}
            </StyledTag>
          ) : (
            <StyledRemoveableTag show title={tag.label} key={`tag_option_${i}`}>
              <StyledIcon
                tw="cursor-pointer"
                icon="close"
                onClick={() => onRemoveTag(tag.value)}
              />
            </StyledRemoveableTag>
          )
        })}
      </StyledTags>
    </StyledWrapper>
  )
}

export { TagInput }

/* TagInput.propTypes = {
  tagMap: PropTypes.array,
  tags: PropTypes.array,
}

TagInput.defaultProps = {
  tags: [],
}
 */
