import { createGlobalStyle } from "styled-components"
import tw from "twin.macro"

const GlobalStyles = createGlobalStyle`
  html {
    overflow-x: hidden;
  }
  body {
    ${tw`text-brand-secondary`}
    background: ${(props) => props.theme.colors.white};
    font-size: 1.7rem;
    font-family: ${(props) => props.theme.fontStacks.light};
  }

  .grecaptcha-badge {
    visibility: hidden !important;
  }

  address {}

  article {}

  aside {}

  footer {}

  header {}

  main {}

  nav {}

  section {}

  img { width: 100%; display: block; }

  h1 {
    font-size: 2.8rem;
    line-height: 1.2;
    font-family: ${(props) => props.theme.fontStacks.heading};
  }

  h2 {
    font-size: 2.4rem;
    line-height: 1.4;
    font-family: ${(props) => props.theme.fontStacks.subheading};
  }

  h3 {
    font-size: 2.2rem;
    font-family: ${(props) => props.theme.fontStacks.subheading};
  }

  h4 {
    font-size: 2.2rem;
    font-family: ${(props) => props.theme.fontStacks.subheading};
  }

  p, li {
    line-height: 1.6;
  }

  a {
    color: currentColor;
    text-decoration: none;
  }

  ${(props) => props.theme.media.tablet_landscape_up`

    h1 {
      font-size: 3.2rem;
      line-height: 1.2;
    }

    * {
      &::-webkit-scrollbar {
        background: #fff;
        border-radius: 0;
        width: 8px;
        padding: 2px 2px 2px 0;
      }
      &::-webkit-scrollbar-button {
      }
      &::-webkit-scrollbar-track {
        /* 3 */
        background: #F5F5F5;
        border-radius: 0;
        padding: 2px 2px 2px 0;
      }
      &::-webkit-scrollbar-track-piece {
        /* 4 */
      }
      &::-webkit-scrollbar-thumb {
        /* 5 */
        background: #d2d2d2;
        border-radius: 0;
        margin: 2px;
      }
      &::-webkit-scrollbar-corner {
        /* 6 */
      }
      &::-webkit-resizer {
        /* 7 */
      }
    }

    h2 {
      font-size: 2.8rem;
      line-height: 4.0rem;
    }
  `}

  @media print {
    /* All your print styles go here */
    nav, header { display: none !important; }
  }

`

export default GlobalStyles
