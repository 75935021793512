import React from "react"
import Icon, { IconSizeEnum } from "lib/Icon"
import tw from "twin.macro"
import styled, { css, keyframes } from "styled-components"
import { Logo } from "../Logo"
import { siteTheme as theme } from "theme"

const StyledLoadingWrapper = styled.div`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${tw`bg-gray-200 bg-opacity-50`}
`

const Rotate = keyframes`
  0% {
    transform: rotateZ(0deg);
  }

  50% {
    transform: rotateZ(180deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
`

const RotateRule = css`
  ${Rotate} 0.5s linear infinite forwards;
`

const StyledLoading = styled.div`
  padding-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  svg:last-of-type {
    // opacity: 0.5;
    transform: rotateZ(0deg);
    animation: ${RotateRule};
  }

  p {
    font-size: 2.1rem;
  }
`

interface LoadingProps {
  children?: React.ReactNode
  withLogo?: boolean
  icon?: string
  loadingText?: string
  includeWrapper?: boolean
}

const Loading: React.FC<LoadingProps> = ({
  children,
  withLogo = false,
  icon = "spinner",
  loadingText = "Indlæser",
  includeWrapper = true,
  ...props
}) => {
  const renderLoading = () => {
    return (
      <>
        {withLogo && <Logo tw="h-28 mb-4" colored />}
        <StyledLoading>
          {withLogo ? (
            <Icon tw="w-20 h-20" size={IconSizeEnum.LG} icon={icon} />
          ) : (
            <Icon tw="w-12 h-12" size={IconSizeEnum.LG} icon={icon} />
          )}
          <p>
            <small>{loadingText}</small>
          </p>
        </StyledLoading>
      </>
    )
  }

  return includeWrapper ? (
    <StyledLoadingWrapper {...props}>{renderLoading()}</StyledLoadingWrapper>
  ) : (
    renderLoading()
  )
}

export { Loading }
