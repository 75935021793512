import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledImg = styled(GatsbyImage)`
  height: 100%;
  max-height: 100%;
  max-width: 100%;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`

function Image({ fixed, fluid, ...props }) {
  if (fixed || fluid) return <StyledImg image={fixed || fluid} />
  return null
}

export { Image }
