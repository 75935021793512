import { useWhyDidYouUpdate } from "hooks/useWhyDidYouUpdate"
import React, { useEffect, useMemo, useRef } from "react"
import { Spring } from "react-spring/renderprops"
import styled from "styled-components"

const StyledAccordion = styled.div`
  overflow: hidden;
`

const StyledCSSAccordion = styled.div<any>`
  max-height: ${(props) =>
    props.toggled ? `${props.maxHeight ? props.maxHeight : "500px"}` : `0`};
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  transition: max-height
    ${(props) => (props.toggled ? props.transitionDuration : `0.2s`)} ease-out;
`

export const Accordion = ({
  children,
  toggled,
  onRest,
  onStart,
  forwardRef,
  maxHeight = "300px",
  transitionDuration = "0.4s",
  ...props
}) => {
  /* useWhyDidYouUpdate("accordion", {
    children,
    toggled,
    onRest,
    onStart,
    forwardRef,
    maxHeight,
    transitionDuration,
    ...props,
  }) */

  return useMemo(
    () => (
      <Spring
        from={{ height: 0 }}
        to={{ height: toggled ? "auto" : 0 }}
        onRest={onRest}
        onStart={onStart}
        config={{
          tension: 500,
          friction: 50,
        }}
      >
        {(styleProps) => (
          <StyledAccordion style={styleProps} ref={forwardRef} {...props}>
            {children}
          </StyledAccordion>
        )}
      </Spring>
    ),
    [toggled, onRest, onStart]
  )
}
