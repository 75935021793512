import React, { useRef } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { motion, AnimatePresence } from "framer-motion"
import { siteTheme as theme } from "theme"
import { useMeasure } from "react-use"

const StyledDropdown = styled(motion.div)`
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 100;
  width: 24rem;
  background: ${theme.colors.white};
  box-shadow: ${theme.mapButtonShadow};
  transform-origin: top;
  // overflow: hidden;
  // overflow-y: visible;
`

const DropdownContent = styled(motion.div)`
  // overflow: hidden;
`

function Dropdown({ children, toggled, zindex = 100, ...props }) {
  const [ref, { height }] = useMeasure()

  const dropdownVariants = {
    open: {
      opacity: 1,
      height,
      scaleY: 1,
      transition: {
        type: "easeInOut",
        duration: 0.15,
      },
    },
    closed: {
      opacity: 0,
      height: 0,
      scaleY: 0.5,
      transition: {
        type: "easeInOut",
        duration: 0.15,
      },
    },
  }

  return (
    <AnimatePresence>
      {toggled && (
        <StyledDropdown
          key="dropdown"
          initial="closed"
          animate="open"
          exit="closed"
          variants={dropdownVariants}
          style={{ zIndex: zindex }}
          {...props}
        >
          <DropdownContent ref={ref}>{children}</DropdownContent>
        </StyledDropdown>
      )}
    </AnimatePresence>
  )
}

export { Dropdown }
