import React from "react"
import styled from "styled-components"
import DefaultButton from "lib/Button"
import { lighten, desaturate, darken } from "polished"
import { siteTheme as theme } from "theme"

const StyledButton = styled(DefaultButton)<IButton>`
  transition: all ease-out 0.15s;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }

  ${(props) =>
    props.appearance === "link" &&
    `
      background: none;
      color: ${props.theme.button.bg};
    `}

  ${(props) =>
    props.invert
      ? `
          background: ${theme.buttonStyles[props.variant].color};
          color: ${theme.buttonStyles[props.variant].background};


          &:focus {
            ${
              props.variant === "transparent"
                ? ``
                : `

                  `
            }

          }

        `
      : `
          background: ${theme.buttonStyles[props.variant].background};
          color: ${theme.buttonStyles[props.variant].color};

          &:focus {

          }
        `}

  &:hover:not(:disabled) {
    ${(props) =>
      props.variant !== "transparent"
        ? `
        &:focus {
          background: ${darken(
            0.075,
            theme.buttonStyles[props.variant].background
          )};
            color: ${darken(0.075, theme.buttonStyles[props.variant].color)};
        }
        ${
          props.invert
            ? `
              background: ${darken(
                0.05,
                theme.buttonStyles[props.variant].color
              )};
              color: ${darken(
                0.05,
                theme.buttonStyles[props.variant].background
              )};
            `
            : `
            background: ${darken(
              0.05,
              theme.buttonStyles[props.variant].background
            )};
            color: ${darken(0.05, theme.buttonStyles[props.variant].color)};
          `
        }`
        : ``}
  }

  span {
    justify-content: center;
  }
`

const StyledIconButton = styled(DefaultButton)`
  background: none;
  padding: 0;
  color: ${theme.colors.black};

  svg {
    color: currentColor;
  }
`

type ButtonVariant =
  | "primary"
  | "secondary"
  | "danger"
  | "cancel"
  | "warning"
  | "default"
  | "transparent"
  | "icon"

type ButtonSize = "sm" | "md" | "lg"

export interface IButton {
  variant: ButtonVariant
  invert?: boolean
  size?: ButtonSize
  children: React.ReactNode
  onClick?: (...args) => void
  icon?: boolean | "left" | "right"
}

const Button: React.FC<
  IButton & React.ButtonHTMLAttributes<JSX.IntrinsicAttributes>
> = ({ children, variant = "default", ...props }) => {
  return variant === "icon" ? (
    <StyledIconButton {...props}>{children}</StyledIconButton>
  ) : (
    <StyledButton variant={variant} {...props}>
      {children}
    </StyledButton>
  )
}

export { Button }
